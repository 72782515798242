import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is our monthly free class at 8 & 9am at The Ville and 10 &
11am at East so bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`CrossFit The Ville’s team competition, True Grit 4, is November 5th.
 Come out and watch the action starting at 9:00am!`}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-4-registration-27992851372?aff=eac2"
      }}>{`https://www.eventbrite.com/e/true-grit-4-registration-27992851372?aff=eac2`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      